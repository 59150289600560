import styled from 'styled-components'

import { COLORS } from '../../constants/styles'

const TrackingStyles = styled.div`
  height: 100%;
  width: 100%;
  font-family: "Poppins", sans-serif;

  .containerr {
    height: 100%;
    padding: 0.5% 2%;
    width: 100%;
    background-color: white;
  }

  .loader {
    margin-top: 5rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .flex-container {
    font-size: 0.8rem;
    width: 100%;
  }

  .holding {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 1em 0 2em 0;
  }

  .logo-holding {
    min-width: 160px !important;
    max-width: 160px !important;
  }

  .grid-item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .grid-prod {
    width: 60vh;
  }

  .grid-courier {
    width: 40vh;
  }

  .subgrid-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
    grid-gap: 3em;

    @media only screen and (max-width: 1072px) {
      margin: 0 15% 0 15%;
    }

    @media only screen and (max-width: 909px) {
      margin: 0 10% 0 10%;
    }

    @media only screen and (max-width: 789px) {
      margin: 0 5% 0 5%;
    }

    @media only screen and (max-width: 698px) {
      margin: 0;
    }
  }

  .item-info {
    display: grid;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 10vh;
    width: 100%;
    margin: 0.5em;
    font-weight: bold;
    box-shadow: 0 5px 5px 0 rgb(0 0 0 / 20%), 0 5px 20px 10px rgb(0 0 0 / 10%);
    border-radius: 40px;
    cursor: pointer;
    color: ${(props) => props.primaryColor};

    @media only screen and (max-width: 600px) {
      margin-bottom: 15em;
    }
  }

  .content-card {
    padding: 2.5em 1.5em;
  }

  .title-card {
    color: ${(props) => props.primaryColor};
    font-weight: bold;
    font-size: 1.2rem;
    padding-bottom: 5px;
  }

  .ul-card {
    list-style-type: none;
    margin: 0;
    padding: 0;
    min-height: 14vh;
  }

  .a-card {
    font-weight: 600;
  }

  .icon-card {
    width: 10em;
    margin: 0 10% 5% 10%;
  }

  .data-grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    width: 60%;
  }

  .data-item {
    padding: 0;
  }

  .card-data-item {
    border-radius: 25px;
    padding: 1vh 0 1vh 0;

    @media only screen and (max-width: 622px) {
      display: flex;
      width: 100%;
      padding: 0;
    }
  }

  .span-card {
    font-weight: 600;
    color: ${(props) => props.primaryColor};
  }

  .clipboard {
    width: 4vh;
    cursor: pointer;
  }

  .copy-item {
    display: flex;
    align-items: center;
  }

  .btn {
    border-radius: 25px;
    padding: 1em 2.5em;
    font-size: 0.8rem;
  }

  .normal:hover,
  .ticket:hover {
    opacity: 0.7;
    cursor: pointer;
  }

  .icon-btn {
    width: 1.5em;
    margin-right: 1em;
    margin-bottom: -0.3em;
  }

  .inactive-icon {
    filter: grayscale(100%);
  }

  .normal {
    color: ${(props) => props.primaryColor};
    border: 1px solid ${(props) => props.primaryColor};
    font-weight: 600;
  }

  .inactive-btn {
    color: ${COLORS.LIGHT_GREY};
    border: 1px solid ${COLORS.LIGHT_GREY};
    font-weight: 600;
  }

  .ticket {
    color: white;
    background-color: ${COLORS.PURPLE};
  }

  ul {
    list-style-type: none;
    margin: 0;
    padding: 0 1em 0 1em;
  }

  li {
    padding: 1vh 4vh 1vh 4vh;
  }

  li > span {
    font-weight: 600;
  }

  .error-label {
    text-align: center;
    padding: 2em;
    width: 23vw;
  }

  .icon-error {
    width: 3.5em;
    display: block;
    margin: 0 auto 2em;
  }

  .error {
    margin-top: 50vh;
    text-align: center;
    font-size: 20px;
  }

  .error-courier {
    text-align: center;
    cursor: default;
  }

  .back {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 2em;
    width: 6em;
    margin: 0.3em 0.3em 1em 0.3em;
    padding-right: 0.8em;
    font-weight: bold;
    box-shadow: 0 5px 5px 0 rgb(0 0 0 / 20%), 0 5px 20px 10px rgb(0 0 0 / 10%);
    border-radius: 20px;
    cursor: pointer;
  }
`

export default TrackingStyles
